@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.slide-exit {
  transform: translate(-100%, 0%);
}
.slide-exit-active {
  transform: translate(-200%, 0%);
  transition: transform 500ms ease-in-out;
}

.slide-group {
  display: flex;/* 
  flex-wrap: nowrap; */
}

.background-animate {
  background-size: 400%;

  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}


/* Light Buttons */
.button-black {
  @apply bg-black py-1 px-5 text-white rounded-sm  hover:text-white font-medium focus:outline-none duration-75;
}

.button-black-light {
  @apply bg-black py-1 px-5 text-white hover:bg-white hover:text-black border border-black rounded  font-medium focus:outline-none duration-150 disabled:bg-gray-100 disabled:border disabled:border-gray-300 disabled:text-gray-300;
}

.button-onboarding-light {
  @apply  py-1 px-5  rounded  font-medium focus:outline-none duration-150 ;
}

.button-outline-light {
  @apply bg-transparent py-1 px-5 text-gray-600 hover:text-black border border-gray-400 hover:border-black rounded font-medium focus:outline-none duration-150 disabled:opacity-50;
}

.button-outline {
  @apply bg-transparent py-1 px-4 text-gray-600 rounded-sm border border-gray-300 hover:text-gray-900 duration-75 font-medium focus:outline-none;
}

.button-primary-light {
  @apply bg-blue-600 py-1 px-5 text-white rounded-sm hover:bg-blue-700 hover:text-white duration-75 font-medium focus:outline-none;
}


/* Dark Buttons */
.button-blue-dark {
  @apply bg-blue-600 py-1 px-3 text-white rounded-sm border border-blue-600 hover:bg-blue-700 hover:text-white duration-75 font-medium focus:outline-none;
}

.button-gray-dark {
  @apply bg-v1_dark-hover py-1 px-3 text-white rounded-sm border border-gray-700 hover:bg-gray-700 hover:text-white duration-75 font-medium focus:outline-none;
}

.button-red-dark {
  @apply bg-red-600 py-1 px-5 text-white rounded-sm border border-red-600 hover:bg-red-700 hover:text-white duration-75 font-medium focus:outline-none;
}

.button-outline-dark {
  @apply bg-transparent py-1 px-3 text-gray-300 rounded-sm border border-gray-600 hover:text-gray-100 hover:border-gray-500 duration-75 font-medium focus:outline-none;
}

.button-complete-dark {
  @apply bg-transparent py-1 px-4 text-white rounded-sm border border-gray-600 hover:text-gray-100 hover:border-gray-500 duration-75 font-medium focus:outline-none;
}

.button-start-dark{
  @apply bg-blue-600 py-1.5 px-5 mb-3 w-full text-white rounded-sm border border-blue-600 hover:bg-blue-700 hover:text-white duration-75 font-medium focus:outline-none disabled:opacity-50;
}

.button-pause-dark {
  @apply bg-gray-600 py-1.5 px-5 mb-3 w-full text-white rounded-sm border border-gray-600 hover:bg-gray-700 hover:text-white duration-75 font-medium focus:outline-none disabled:opacity-50;
}



/* Inputs */
.label-dark {
  @apply text-xs font-medium mb-1;
}

.label-light {
  @apply text-xs text-gray-500 mb-1;
}

.input-light {
  @apply px-3 py-2 border border-gray-300 text-black placeholder:text-gray-400 rounded focus:outline-black;
}

.input-dark {
  @apply px-3 py-2 bg-v1_dark-hover text-white placeholder:text-gray-400 rounded  focus-visible:outline-gray-600 focus:outline-gray-600;
}


/* Modals */
.modal-footer-dark {
  @apply p-4 border border-transparent border-t-v1_dark-hover;
}



.sidebar-header-title-dark {
  @apply text-gray-400 mb-3;
}

.puzzle-item-dark {
  @apply w-full py-2 px-3 text-left focus:outline-none
}

.clue-item-dark {
  @apply w-full py-1.5 px-4 pr-3 rounded-sm mb-2 text-left focus:outline-none
}

.toast-dark {
  @apply bg-v1_dark-hover border border-v1_dark-hover text-white;
}

.auth-input {
  @apply px-3 py-3 w-full border border-gray-300 text-black placeholder:text-gray-400 rounded focus:outline-black text-base;
}

.auth-button {
  @apply bg-black py-3 px-5 w-full text-white hover:bg-white hover:text-black border border-black font-medium text-base rounded focus:outline-none duration-150 disabled:bg-gray-100 disabled:border disabled:border-gray-300 disabled:text-gray-300;
}